/* You can add global styles to this file, and also import other style files */
/*header {
  width: 960px;
  height:230px;
  padding:0px;
  margin:0px;
  border:0px;
}
*/
body {
  margin:0px;
  padding:0px;
  border:0px;
}


h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
  -webkit-margin-before: 0px;
}

h2 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 150%;
}

h3 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}

h4 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 80%;
}

p {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.indent {
  color: #000;
  padding-left: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}

.nav {
  display: block;
  background-color: #50B0D0;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.navbar-collapse {
  padding: 0px 0px 0px 0px;
}

.navbar-collapse ul{
  padding:0px 10px;
}

.navbar-collapse ul li{
  display: block;
  display: inline-block;
  list-style: none;
  color: #000;
  background-color: #A0D9E9;
  padding: 5px 10px 2px 10px;
  border: 0px 0px 1px 0px;
  Margin: 10px 2px 0px 2px;

  -moz-border-radius: 6px 6px 0 0; /* Firefox */
  -webkit-border-radius: 6px 6px 0 0; ; /* Safari, Chrome */
  -khtml-border-radius: 6px 6px 0 0;  /* Konqueror */
  border-radius: 6px 6px 0 0;  /* CSS3 */
  border-style: solid;
  border-width: 2px;
  border-color: #A0D9E9;
  border-bottom-color: #50B0D0;

  outline-style: none;
  text-decoration: none;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}

.navbar-collapse ul li:hover{
  padding-top: 10px;
  Margin-top: 0px;
  /*background-color: #AEF;*/
}

.navbar-collapse ul li:active{
  background-color: #50E0FF;
}

.navbar-collapse ul li:focus{
  background-color: #AEF;
}

.navbar-collapse ul .active{
  background-color: #AEF;
  border-color: #AEF;
  border-bottom-color: #AEF;
}

.navbar-collapse ul li a{
  text-decoration: none;
  padding: 0;
}

.navbar-collapse ul li a:active{
  background-color: #AEF;
}

.navbar-collapse ul li a:hover{
  background-color: inherit;
}

.navbar-collapse ul li a:focus{
  background-color: inherit;
}

.navbar-collapse ul li a{
  /*background-color: #AEF;*/
}

ul li{
  color: #000;
  padding: 1px 10px 5px 10px;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}

.content{
  /*width: 960px;*/
  background-color: #AEF;
  padding:10px;
}

.navigation{
  margin: auto;
  /*width: 960px;*/
}

img {
  padding:0px;
}

.activeSong {
  color: #3060A0;
  font-weight: bold;
}

.passiveSong {
  color: #369;
}

.activeSong:hover, .passiveSong:hover{
  cursor:pointer;
  text-decoration: underline;
}

.hamburger {
  width:100%;
  height:12px;
  padding:0px;
  list-style: none;
  display:inline-block;
}

.hamburger ul {
  padding:0px;
  width:100%;
}

.hamburger li {
  /*width:100%;*/
  padding:0px;
  background-color: brown;
}


.hamburger a{
  width:100%;
  color: white;
}
.navbar-toggle{
  width:100%;
  padding:10px;
  margin:0px;
}

.even {
  background-color: #5098C0; /*#9099B0;*/
  display:inline-block;
  padding:0px 10px 0px 10px;
}

.odd {
  background-color: #60B0D0; /*#8090B0;*/
  display:inline-block;
  padding:0px 10px 0px 10px;
}

#musicProgressBar {
  margin-top: 3px;
}

td {
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: top;
  padding-right: 4pt;
  padding-left: 4pt;
}

.background-pane {
  background-color: #5098C0;
  padding: 0px 0px 0px 0px;
  margin: 0px;
  min-height:100vh;
}
